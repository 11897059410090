import React from "react";
import { capitalizeFirstLetter } from "../utils/utils";
import { useAlert } from "../context/AlertContext";

export default function CustomAlert(): JSX.Element {
	const { alertConfig, closeAlert } = useAlert();

	return (
		<div className="alert">
			<div className={`alert-content ${alertConfig.type}`}>
				{alertConfig.type !== "" && <h2>{capitalizeFirstLetter(alertConfig.type)}</h2>}
				{alertConfig.message}
				<button className="button-primary" onClick={closeAlert}>Ok</button>
			</div>
		</div>
	);
}
