import CryptoJS from "crypto-js";

export async function fetchIPAddressAndCreateAnonymousUserID() {
	let userIP = "";
	try {
		const response = await fetch("https://api.ipify.org?format=json");
		const data = await response.json();
		if (data.ip) userIP = data.ip;
	} catch (error) {
		console.error("Error fetching IP address: ", error);
	}
	if (!userIP) userIP = window.location.hostname;
	// Hash the IP address, so we don't store sensitive data
	return CryptoJS.SHA256(userIP).toString();
}
