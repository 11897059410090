import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LOGO } from "../data/images";
import { useTranslation } from "react-i18next";
import CTA from "./CTA";
import { scroller } from "react-scroll";

export default function Header(): JSX.Element {
	const [changeLanguage, setChangeLanguage] = React.useState(false);
	const chooseLanguageRef = useRef<HTMLDivElement>(null);

	const { t } = useTranslation("Header");

	// Handling click outside to reset changeLanguage
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (chooseLanguageRef.current && !chooseLanguageRef.current.contains(event.target as Node))
				setChangeLanguage(false);
		};

		document.body.addEventListener("click", handleClickOutside);
		return () => document.body.removeEventListener("click", handleClickOutside);
	}, []);

	return (
		<header>
			<div className="headerContainer">
				<Link to="/home">
					<img id="logo" alt={t("header.logoAlt")} src={LOGO} />
				</Link>
				{/* <SearchBar /> */}
				<Menu />
				{/* <ChooseLanguage
				ref={chooseLanguageRef}
				changeLanguage={changeLanguage}
				setChangeLanguage={setChangeLanguage}
				/> */}
				{/* <Menu /> */}
			</div>
		</header>
	);
}

function Menu() {
	const [isOpen, setIsOpen] = React.useState(false);
	const toggleMenu = () => setIsOpen(!isOpen);

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	}, [isOpen]);

	return (
		<>
			<div className="hamburger" onClick={toggleMenu}>
				<div className="line"></div>
				<div className="line"></div>
				<div className="line"></div>
			</div>
			<MenuItems isOpen={isOpen} setIsOpen={setIsOpen} />
		</>
	);
}

function MenuItems({
	isOpen,
	setIsOpen,
}: {
	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
	const closeMenu = () => setIsOpen(false);
	const navigate = useNavigate();

	// Function to handle navigation and scrolling
	const handleNavigation = (section: string) => {
		closeMenu();
		navigate(`/${section === 'home' ? '' : section}`);

		// Also trigger scroll behavior
		scroller.scrollTo(section, {
			duration: 500,
			delay: 0,
			smooth: true,
		});
	};

	// check window size and set menuItemsSmall if window is smaller than 600px
	const [windowSize, setWindowSize] = React.useState(window.innerWidth);

	React.useEffect(() => {
		const handleResize = () => {
			setWindowSize(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	if (windowSize < 850 && !isOpen) return null;
	return (
		<div className={windowSize < 850 ? "menuItemsSmall" : "menuItems"}>
			<button className="menuItem" onClick={() => handleNavigation('features')}>
				Features
			</button>
			<button className="menuItem" onClick={() => handleNavigation('security')}>
				Security
			</button>
			<button className="menuItem" onClick={() => handleNavigation('products')}>
				Products
			</button>
			<button className="menuItem" onClick={() => handleNavigation('team')}>
				Team
			</button>
			<button className="menuItem" onClick={() => handleNavigation('faq')}>
				FAQ
			</button>
			<button className="menuItem" onClick={() => handleNavigation('contact')}>
				<CTA />
			</button>
		</div>
	);
}



// const ChooseLanguage = React.forwardRef<
// 	HTMLDivElement,
// 	{
// 		changeLanguage: boolean;
// 		setChangeLanguage: React.Dispatch<React.SetStateAction<boolean>>;
// 	}
// >(({ changeLanguage, setChangeLanguage }, ref) => {
// 	const currentLanguage = i18next.language as e.Languages;
// 	const [currentLanguageFlag, setCurrentLanguageFlag] = React.useState(img.FLAGS[currentLanguage]);

// 	const handleSelectLanguage = (lang: string) => {
// 		setChangeLanguage(false);
// 		if (lang === currentLanguage) return;
// 		i18next.changeLanguage(lang);
// 		localStorage.setItem("language", lang);
// 		setCurrentLanguageFlag(img.FLAGS[lang]);
// 	};

// 	return (
// 		<div className="language" ref={ref}>
// 			{changeLanguage && (
// 				<div className="flagPopup">
// 					<img
// 						className={currentLanguage === e.Languages.DUTCH ? "flag flagActive" : "flag"}
// 						src={img.FLAGS[e.Languages.DUTCH]}
// 						onClick={() => handleSelectLanguage(e.Languages.DUTCH)}
// 						alt="Dutch"
// 						title="Dutch"
// 					/>
// 					<img
// 						className={currentLanguage === e.Languages.ENGLISH ? "flag flagActive" : "flag"}
// 						src={img.FLAGS[e.Languages.ENGLISH]}
// 						onClick={() => handleSelectLanguage(e.Languages.ENGLISH)}
// 						alt="English"
// 						title="English"
// 					/>

// 					<img
// 						className={currentLanguage === e.Languages.GERMAN ? "flag flagActive" : "flag"}
// 						src={img.FLAGS[e.Languages.GERMAN]}
// 						onClick={() => handleSelectLanguage(e.Languages.GERMAN)}
// 						alt="German"
// 						title="German"
// 					/>
// 				</div>
// 			)}
// 			<img
// 				className="flag"
// 				src={currentLanguageFlag}
// 				onClick={() => setChangeLanguage(true)}
// 				alt="Change Language"
// 			/>
// 		</div>
// 	);
// });
