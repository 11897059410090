import React, { useRef } from "react";
import InfoCard from "../../components/InfoCard";
import useImageVisability from "../../hooks/useImageVisability";


export default function Features() {
	const iconRef = useRef<HTMLDivElement | null>(null);
	const isVisible = useImageVisability(iconRef);

	return (
		<>
			<section className="features">
				<h2 className="sectionHeader">Waarom Verdic?</h2>
				<h3>Volledige en actuele juridische kennis. Direct toegankelijk.</h3>
				<p>Verdic helpt om snel alle relevante feiten op te sporen, waardoor recht wordt gesproken op basis van volledigheid, niet op basis van beschikbare zoektijd.</p>
			</section>
			<div className="verdicFeatureCards" ref={iconRef}>
				<InfoCard isVisible={isVisible} imgPosition="left" title={header5} text={text5} timeout={100} imgSrc={"img/icons/handshake.png"} />
				<InfoCard isVisible={isVisible} imgPosition="left" title={header1} text={text1} timeout={1000} imgSrc={"img/icons/search_folder.png"} />
				<InfoCard isVisible={isVisible} imgPosition="left" title={header2} text={text2} timeout={1500} imgSrc={"img/icons/legal-system.png"} />
				<InfoCard isVisible={isVisible} imgPosition="left" title={header3} text={text3} timeout={2000} imgSrc={"img/icons/balance.png"} />
				<InfoCard isVisible={isVisible} imgPosition="left" title={header4} text={text4} timeout={2500} imgSrc={"img/icons/customization.png"} />
			</div>
		</>
	);
}



const header1 = "Wet- en regelgeving – doorzoekbaar en voorzien van toelichting"
const text1 = "Verdic biedt toegang tot de complete, doorzoekbare Nederlandse wet- en regelgeving, inclusief toelichtingen. Altijd actueel, altijd betrouwbaar."

const header2 = "Jurisprudentie – van rechtbank tot Hoge Raad"
const text2 = "Vind relevante uitspraken op basis van thema, wetsartikel of specifieke casus. Verdic is getraind op juridische context en begrijpt het verschil tussen een voorlopige voorziening en een vernietigingsgrief."

const header3 = "Getraind op het Nederlandse juridische domein"
const text3 = "Onze AI is specifiek getraind op Nederlandse rechtsbronnen, terminologie en context. Geen Amerikaanse datasets. Geen ruis. Alleen wat telt."

const header4 = "Afgestemd op jouw praktijkgebied"
const text4 = "Of je nu actief bent in strafrecht, bestuursrecht of ondernemingsrecht — Verdic filtert en levert antwoorden die voor jouw vakgebied relevant zijn."

const header5 = "Samenwerking met Theon"
const text5 = "Theon beheert jouw interne kennis. Verdic beheerst het externe juridische landschap. Samen bieden ze een 360° juridisch inzicht — zónder jouw vertrouwelijke data te delen."




