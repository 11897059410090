import * as e from "./enums";

export const LOGO = "/img/logos/prometheon.png";

export const FLAGS: Record<any, string> = {
	// [e.Languages.ENGLISH_USA]: "/img/flags/usa.webp",
	[e.Languages.DUTCH]: "/img/flags/netherlands.webp",
	[e.Languages.ENGLISH]: "/img/flags/uk.webp",
	[e.Languages.GERMAN]: "/img/flags/germany.webp",
};

export const BG: Record<any, string> = {
	[e.BG.HOME]: "/img/bg/bg_8.jpg",
	[e.BG.SECURITY]: "/img/bg/bg_01.jpg",
	[e.BG.BENEFITS]: "/img/bg/bg_02.jpg",
	[e.BG.CONTACT]: "/img/bg/bg_04.jpg",
};
