import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Products(): JSX.Element {
	const { t } = useTranslation("Products");


	return (
		<main>
			<section className="features products">
				<h2 className="sectionHeader">{t("sectionHeader")}</h2>
				<div className="productCards">
					<ProductCard title={t("theon.title")} text1={t("theon.text.p1")} text2={t("theon.text.p2")} image={"img/logos/theon.png"} />
					<ProductCard title={t("Evora.title")} text1={t("Evora.text.p1")} text2={t("Evora.text.p2")} image={"img/logos/evora.png"} stage={t("Evora.stage")} />
					<ProductCard title={t("Verdic.title")} text1={t("Verdic.text.p1")} text2={t("Verdic.text.p2")} image={"img/logos/verdic.png"} stage={t("Verdic.stage")} link="/verdic" />
				</div>
			</section>
		</main>
	);
}

function ProductCard({ title, text1, text2, image, stage, link }: { title: string, text1: string, text2: string, image: string, stage?: string, link?: string }) {
	const { t } = useTranslation("Products");

	return (
		<div className="logoCardContainer">
			<Link to={link || ""}>
				<img className="logo" src={image} alt={title} />
			</Link>
			<div className="card">
				{stage && <h3>{stage}</h3>}
				<p>{text1}</p>
				<p>{text2}</p>
				{link && <Link to={link} style={{ width: "100%", textAlign: "right" }}>{t("learnMore")}</Link>}
			</div>
		</div>
	);
}
