import { getFirestore, collection, addDoc } from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";
import { app, auth } from "../firebaseConfig";
import { fetchIPAddressAndCreateAnonymousUserID } from "../utils/fetchIP";

const db = getFirestore(app);

export async function storeInFirebase(collectionName: string, data: any) {

	const userID = await fetchIPAddressAndCreateAnonymousUserID();
	data.userID = userID;

	data.date = new Date();

	try {
		await signInAnonymously(auth);
		await addDoc(collection(db, collectionName), data);
		return true;
	} catch (error) {
		console.error(error);
		return false;
	}
}

