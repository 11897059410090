import React, { useEffect, useState } from "react";

export default function InfoCard({ imgSrc, title, text, isVisible, timeout, imgPosition }: { imgSrc?: any; title: string; text: any, isVisible: boolean, timeout?: number, imgPosition?: string }) {

	const [showImg, setShowImg] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setTimeout(() => {
				setShowImg(true);
			}, timeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible]);


	return (
		<div className="card infoCard">
			{imgSrc && imgPosition === "left" && <img className={`imgBig ${showImg ? 'icon-fade-in' : 'hidden'}`} src={imgSrc} alt={title} />}
			<div className="card-text">
				<div className="flexRow">
					{imgSrc && <img className={`imgSmall ${showImg ? 'icon-fade-in' : 'hidden'}`} src={imgSrc} alt={title} />}
					<h2>{title}</h2>
				</div>
				<p>{text}</p>
			</div>
			{imgSrc && imgPosition === "right" && <img className={`imgBig ${showImg ? 'icon-fade-in' : 'hidden'}`} src={imgSrc} alt={title} />}
		</div>
	);
}
