import React, { useState, useEffect } from "react";
// import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import CaseCalculator from "./CaseCalculator";
import Features from "./Features";
import { useAlert } from "../../context/AlertContext";
import Loading from "../../components/Loading";
import { isValidEmail } from "../../utils/utils";

export default function Verdic(): JSX.Element {
	// const { t } = useTranslation("Team");

	// Add this effect to scroll to top when component mounts
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<main className="verdic">
			<div className="hero">
				<img className="logo" src={"img/logos/verdic.png"} alt={"Verdic"} />
				<h1>De juridische kennisexpert van Nederland</h1>
				<h2>Altijd up-to-date. Altijd relevant. Speciaal getraind voor de Nederlandse rechtspraktijk.</h2>
				<WaitlistButton />
			</div>
			<br />
			<br />
			<br />
			<Features />
			<br />
			<br />
			<br />
			<CaseCalculator />
			<br />
			<br />
			<br />
			<div className="card waitlist">
				<div className="waitlistBigScreen"><WaitlistButton /></div>
				<p>
					Zet de eerste stap richting AI-geoptimaliseerd juridisch werk.
					Meld je aan voor onze wachtlijst en ontdek hoe Verdic jouw praktijk kan versterken.
				</p>
				<div className="waitlistSmallScreen"><WaitlistButton /></div>
			</div>
			<br />
			<br />
			<br />
		</main >
	);
}

function WaitlistButton() {
	const [showWaitListModal, setShowWaitListModal] = useState(false);

	const handleClick = () => {
		setShowWaitListModal(!showWaitListModal);
	}

	return (
		<>
			<button className="startUsingTheon button-primary" onClick={handleClick}>Aanmelden voor de wachtlijst</button>
			{showWaitListModal && <WaitListModal showWaitListModal={showWaitListModal} setShowWaitListModal={setShowWaitListModal} />}
		</>
	);
}

function WaitListModal({ showWaitListModal, setShowWaitListModal }: { showWaitListModal: boolean, setShowWaitListModal: (showWaitListModal: boolean) => void }) {
	const { triggerAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);

	const message = "Ik wil graag aanmelden voor de wachtlijst van Verdic"

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		company: "",
		message: message,
	});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
	};

	const handleClick = () => {
		setShowWaitListModal(!showWaitListModal)
	}

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (formData.name.trim() === "" || formData.email.trim() === "" || formData.company.trim() === "")
			return triggerAlert("Please fill in all fields.");

		if (!isValidEmail(formData.email))
			return triggerAlert("Please enter a valid email address.");

		setIsLoading(true);

		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
		const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID_WAITLIST!;
		const userID = process.env.REACT_APP_EMAILJS_USER_ID!;

		emailjs.send(serviceID, templateID, formData, userID).then(
			(response) => {
				console.log(response.status, response.text);
				setIsLoading(false);
				triggerAlert("Message sent successfully!", "success");
				setFormData({ name: "", email: "", company: "", message: message });
				setShowWaitListModal(false);
			},
			(error) => {
				console.log("FAILED...", error);
				setIsLoading(false);
				triggerAlert("Message failed to send.", "error");
			}
		);
	}

	if (isLoading) return <Loading />;
	return (
		<div className="waitListModal" onClick={handleClick}>
			<div className="waitListModalContent" onClick={(e) => e.stopPropagation()}>
				<button className="closeButton" onClick={handleClick}>&times;</button>
				<h2>Aanmelden voor de wachtlijst</h2>
				<br />
				{/* <p>Stuur een e-mail naar <a href={`mailto:${email}`}>{email}</a> om je op de wachtlijst te zetten.</p> */}
				<form>
					<label htmlFor="name">Naam</label>
					<input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
					<label htmlFor="email">Email</label>
					<input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
					<label htmlFor="company">Bedrijf</label>
					<input type="text" id="company" name="company" value={formData.company} onChange={handleChange} required />
					<br />
					<button type="submit" onClick={handleSubmit} className="button-primary">Verstuur</button>
				</form>
			</div>
		</div>
	);
}
