import { EMAIL } from "../data/constants"
import { BACKEND_URL } from "../data/constants";

export default async function askTheon(question: string): Promise<string> {
	console.log("API CALL: askTheon");
	try {
		console.log(BACKEND_URL + "ask");
		console.log("Request data:", { question });
		const axios = (await import("axios")).default;
		const maxRetries = 3; // Maximum number of retries
		let attempt = 0; // Current attempt count

		while (attempt < maxRetries) {
			try {
				const response = await axios.post(BACKEND_URL + "ask", { question });
				console.log("Response data:", response.data);
				return response.data.answer;
			} catch (error: any) {
				if (error.code === 'ERR_NETWORK_CHANGED') {
					attempt++;
					console.warn(`Network error occurred. Retrying... (${attempt}/${maxRetries})`);
					if (attempt === maxRetries) {
						console.error("Max retries reached. Error occurred:", error.response ? error.response.data : error.message);
						return "I am not sure. Send us an email at " + EMAIL + " and our team will be happy to assist you.";
					}
				} else {
					console.error("Error occurred:", error.response ? error.response.data : error.message);
					return "I am not sure. Send us an email at " + EMAIL + " and our team will be happy to assist you.";
				}
			}
		}
	} catch (error: any) {
		console.error("Error occurred:", error.response ? error.response.data : error.message);
		return "I am not sure. Send us an email at " + EMAIL + " and our team will be happy to assist you.";
	}

	// Ensure a return statement is present
	return "I am not sure. Send us an email at " + EMAIL + " and our team will be happy to assist you.";
}