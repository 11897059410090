import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import InfoCard from "../components/InfoCard";
import useImageVisability from "../hooks/useImageVisability";

export default function Features(): JSX.Element {
	const { t } = useTranslation("Features");

	const iconRef = useRef<HTMLDivElement | null>(null);
	const isVisible = useImageVisability(iconRef);

	return (
		<Element name="features">
			<main>
				<section className="features features">
					<h2 className="sectionHeader">{t("header1")}</h2>
					{/* <h2>Search across all your company's data</h2> */}
					<div className="wrapper">
						<div className="containterHeading" >
							{/* {window.innerWidth > 750 && <img className="bgImg" src={img.BG[e.BG.BENEFITS]} />} */}
							<div className={`containterText`}>
								<h3 className="intro">{t("intro")}</h3>
							</div>
							<div className="iconBG"><img className="icon" src="img/icons/loupe.png" alt="loupe" /></div>
						</div>
						<div className="benefitCards" ref={iconRef}>
							<InfoCard isVisible={isVisible} imgPosition="right" title={t("1.title")} text={t("1.text")} timeout={100} imgSrc="img/icons/search_pc.png" />
							<InfoCard isVisible={isVisible} imgPosition="right" title={t("2.title")} text={t("2.text")} timeout={500} imgSrc="img/icons/analytics.png" />
							<InfoCard isVisible={isVisible} imgPosition="right" title={t("3.title")} text={t("3.text")} timeout={1000} imgSrc="img/icons/private-account.png" />
							<InfoCard isVisible={isVisible} imgPosition="right" title={t("4.title")} text={t("4.text")} timeout={1500} imgSrc="img/icons/scale-up.png" />
						</div>
					</div>
				</section>
			</main>
		</Element>
	);
}
