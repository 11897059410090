import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function ErrorPage({ message }: { message: string }): JSX.Element {
	const { t } = useTranslation("ErrorPage");

	return (
		<main>
			<div className="errorPage">
				<img src={"/img/icons/error404.png"} alt="Error 404" />
				<div className="errorText">
					<div>
						<h2>{t("error")}</h2>
						<p>{message}</p>
					</div>
					<Link to="/">
						<button className="button-primary">{t("backToHome")}</button>
					</Link>
				</div>
			</div>
		</main>
	);
}
