import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading";
import { storeInFirebase } from "../../api/firebase";

export default function CaseCalculator() {
	const [loading, setLoading] = useState(false);

	const [aantalAdvocatenPerCase, setAantalAdvocatenPerCase] = useState(2);
	const [aantalCasesPerJaar, setAantalCasesPerJaar] = useState(30);
	const [aantalUrenPerCase, setAantalUrenPerCase] = useState(20);
	const [uurloon, setUurloon] = useState(80);
	const [showROI, setShowROI] = useState(false);

	// Add these new state variables for animation control
	const [showYear1Card, setShowYear1Card] = useState(false);
	const [showYear2Card, setShowYear2Card] = useState(false);
	const [showResultText, setShowResultText] = useState(false);
	const [showResetButton, setShowResetButton] = useState(false);

	const { year1ROI, year2ROI } = calculateROI(aantalAdvocatenPerCase, aantalCasesPerJaar, aantalUrenPerCase, uurloon);

	// Add useEffect to handle the sequential animations
	useEffect(() => {
		if (showROI) {
			// Reset all visibility states when starting new calculation
			setShowYear1Card(false);
			setShowYear2Card(false);
			setShowResultText(false);
			setShowResetButton(false);

			// Schedule the sequential fade-ins
			setTimeout(() => setShowYear1Card(true), 100);
			setTimeout(() => setShowYear2Card(true), 1200);
			setTimeout(() => setShowResultText(true), 2200);
			setTimeout(() => setShowResetButton(true), 2200);
		} else {
			setShowYear1Card(false);
			setShowYear2Card(false);
			setShowResultText(false);
			setShowResetButton(false);
		}
	}, [showROI]);

	const handleSubmit = async (e: React.FormEvent) => {
		setLoading(true);
		e.preventDefault();

		await storeInFirebase("caseData", { aantalAdvocatenPerCase, aantalCasesPerJaar, aantalUrenPerCase, uurloon, year1ROI, year2ROI })
		setLoading(false);
		setShowROI(true);
	};

	return (
		<>
			{loading && <Loading />}
			<h2 className="sectionHeader">Business Case: Advocatuur</h2>
			<div className="card verdicCase">
				<div className="flexRow" style={{ alignItems: "flex-start" }}>
					<div>
						<h3>Efficiëntie. Snelheid. Besparing.</h3>
						<p>
							Een advocatenkantoor ervaart jaarlijks honderden uren handmatig speurwerk bij het analyseren van duizenden pagina's aan documenten vlak voor zittingen.
							Met Theon + Verdic wordt dat teruggebracht tot enkele uren.
							Op basis van onze ervaringen zijn we ervan overtuigd dat een tijdsbesparing in dit specifieke gebruiksscenario van 90% haalbaar is.
						</p>
					</div>
					<img className="calculatorIcon" src="img/icons/calculator.png" alt="search" />
				</div>
				<p>
					Vul de gegevens hieronder in om een beeld te krijgen van de Return On Investment (ROI) voor jouw praktijk.
				</p>
				<form>
					<div className="calcRow">
						<div className="flexRow">
							<label htmlFor="aantalCasesPerJaar">Aantal cases per jaar</label>
							{showROI ? (<span>{aantalCasesPerJaar}</span>
							) : (<input type="number" id="aantalCasesPerJaar" placeholder="30" value={aantalCasesPerJaar} onChange={(e) => setAantalCasesPerJaar(Number(e.target.value))} />)
							}
						</div>
						<div className="flexRow">
							<label htmlFor="aantalAdvocatenPerCase">Aantal advocaten per case</label>
							{showROI ? (<span>{aantalAdvocatenPerCase}</span>
							) : (<input type="number" id="aantalAdvocatenPerCase" placeholder="2" value={aantalAdvocatenPerCase} onChange={(e) => setAantalAdvocatenPerCase(Number(e.target.value))} />)
							}
						</div>
					</div>

					<div className="calcRow">
						<div className="flexRow">
							<label htmlFor="aantalUrenPerCase">Aantal uren per case per advocaat</label>
							{showROI ? (<span>{aantalUrenPerCase}</span>
							) : (<input type="number" id="aantalUrenPerCase" placeholder="20" value={aantalUrenPerCase} onChange={(e) => setAantalUrenPerCase(Number(e.target.value))} />)
							}
						</div>
						<div className="flexRow">
							<label htmlFor="Uurloon">Uurloon van 1 advocaat (interne kosten)</label>
							{showROI ? (<span>{uurloon}</span>
							) : (<input type="number" id="Uurloon" placeholder="80" value={uurloon} onChange={(e) => setUurloon(Number(e.target.value))} />)
							}
						</div>
					</div>
				</form>
				<br />


				{showROI ? (
					<>
						<div className="calcRow">
							<div className={`ROIcard ${showYear1Card ? 'fade-in' : 'hidden'}`}>
								<h3>ROI in het eerste jaar</h3>
								<h3 style={{ color: year1ROI > 0 ? "green" : "red" }}>{year1ROI}%</h3>
							</div>
							<div className={`ROIcard ${showYear2Card ? 'fade-in' : 'hidden'}`}>
								<h3>ROI in de opvolgende jaren</h3>
								<h3 style={{ color: year2ROI > 0 ? "green" : "red" }}>{year2ROI}%</h3>
							</div>
						</div>


						<div className={showResultText ? 'fade-in' : 'hidden'} style={{ width: "100%" }}>
							{year2ROI <= 0 ? (
								<p>
									<span style={{ fontWeight: "bold" }}>Let op:</span> de getallen hierboven zijn slechts een indicatie en <span style={{ fontWeight: "bold" }}>mogelijk niet representatief</span> voor jouw praktijk.
									<br /><Link to="/contact"> Neem contact op voor een persoonlijke analyse.</Link>
								</p>
							) : (
								<p>
									Uiteraard zijn de getallen hierboven slechts een indicatie.
									<Link to="/contact"> Neem contact op voor een persoonlijke analyse.</Link>
								</p>
							)}
						</div>
						<br />
						<div className={`button-secondary ${showResetButton ? 'fade-in' : 'hidden'}`} onClick={() => setShowROI(false)}>
							Reset de berekening
						</div>
					</>
				) : (
					<div className="button-primary" onClick={handleSubmit}>Bereken Return On Investment</div>
				)}
			</div >
		</>
	);
}



function calculateROI(aantalAdvocatenPerCase: number, aantalCasesPerJaar: number, aantalUrenPerCase: number, uurloon: number) {
	const timeReductionFactor = 10
	const hardwareMarkup = 0.25
	const hourlyRate = 125
	const durationOfOnboardingTraining = 3
	const estimatedHoursOfImplementation = 16
	const hardwareCost = 6000
	const onboardingCost = 3000
	const verdicCoreMonthlySubscriptionCost = 2000
	const verdicServicePackageCost = 4000

	const hardwareYear1 = hardwareCost * (1 + hardwareMarkup)
	const installationOfHardware = estimatedHoursOfImplementation * hourlyRate
	const oppertunityCost = durationOfOnboardingTraining * uurloon * (aantalCasesPerJaar / aantalAdvocatenPerCase)
	const totalImplementationCost = hardwareYear1 + installationOfHardware + onboardingCost + oppertunityCost

	const verdicCoreSubscriptionCost = verdicCoreMonthlySubscriptionCost * 12
	const totalRecurringCost = verdicCoreSubscriptionCost + verdicServicePackageCost

	const hoursSpendWithoutVerdic = aantalAdvocatenPerCase * aantalCasesPerJaar * aantalUrenPerCase
	const yearlyCostWithoutVerdic = hoursSpendWithoutVerdic * uurloon

	const hoursSpendWithVerdic = hoursSpendWithoutVerdic / timeReductionFactor
	const yearlySalaryCostWithVerdic = hoursSpendWithVerdic * uurloon
	const yearlySavingsInSalaries = yearlyCostWithoutVerdic - yearlySalaryCostWithVerdic
	const netBenefit = yearlySavingsInSalaries - totalRecurringCost

	const year1ROI = Math.round((netBenefit - totalImplementationCost) / (totalImplementationCost + totalRecurringCost) * 100)
	const year2ROI = Math.round(netBenefit / totalRecurringCost * 100)

	return { year1ROI, year2ROI }
}
