import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CTA from "../components/CTA";
import { Link } from "react-router-dom";
import SearchButton from "../components/SearchButton";

export default function Home(): JSX.Element {
	const { t } = useTranslation("Home");

	return (
		<main className="home">
			<div className="hero-container">

				<div className="hero">
					<h1>{t("header1")}
						<br />{t("header2")}</h1>
					<h2>{t("subHeader")}</h2>
					<Link to="/contact"><CTA /></Link>
				</div>

				<AnimatedDemo />
			</div>
		</main>
	);
}

function AnimatedDemo() {
	const { t } = useTranslation("Home");

	const [currentHeadlineIndex, setCurrentHeadlineIndex] = useState(0);
	const [displayedQuestion, setDisplayedQuestion] = useState("");
	const [displayedAnswer, setDisplayedAnswer] = useState("");
	const [charIndex, setCharIndex] = useState(0);
	const [wordIndex, setWordIndex] = useState(0);
	const [animationStage, setAnimationStage] = useState<'typing' | 'buttonClick' | 'answering' | 'waiting' | 'nextQuestion'>('typing');
	const [displayedQuestionIndex, setDisplayedQuestionIndex] = useState(-1);

	const typingSpeedInMilliseconds = 100;
	const waitBeforeButtonClickInMilliseconds = 800;
	const wordTypingSpeedInMilliseconds = 100;
	const pauseDurationInMilliseconds = 2000;

	const QandA = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

	useEffect(() => {
		const currentQuestion = t(`${QandA[currentHeadlineIndex]}.q`);
		const currentAnswer = t(`${QandA[currentHeadlineIndex]}.a`);
		const answerWords = currentAnswer.split(' ');

		if (animationStage === 'typing' && charIndex < currentQuestion.length) {
			// Typing question effect
			const timeout = setTimeout(() => {
				setDisplayedQuestion((prev) => prev + currentQuestion[charIndex]);
				setCharIndex((prev) => prev + 1);
			}, typingSpeedInMilliseconds);
			return () => clearTimeout(timeout);
		}
		else if (animationStage === 'typing' && charIndex === currentQuestion.length) {
			// Question fully typed - simulate button click
			const timeout = setTimeout(() => {
				setAnimationStage('buttonClick');
			}, waitBeforeButtonClickInMilliseconds);
			return () => clearTimeout(timeout);
		}
		else if (animationStage === 'buttonClick') {
			// Move to answering stage and update displayed question
			setAnimationStage('answering');
			setWordIndex(0);
			setDisplayedAnswer("");
			setDisplayedQuestionIndex(currentHeadlineIndex);
			return;
		}
		else if (animationStage === 'answering' && wordIndex < answerWords.length) {
			// Word by word answer animation
			const timeout = setTimeout(() => {
				setDisplayedAnswer((prev) =>
					prev + (wordIndex > 0 ? ' ' : '') + answerWords[wordIndex]);
				setWordIndex((prev) => prev + 1);
			}, wordTypingSpeedInMilliseconds);
			return () => clearTimeout(timeout);
		}
		else if (animationStage === 'answering' && wordIndex === answerWords.length) {
			// Answer completed - wait before starting next question
			const timeout = setTimeout(() => {
				setAnimationStage('nextQuestion');
			}, pauseDurationInMilliseconds);
			return () => clearTimeout(timeout);
		}
		else if (animationStage === 'nextQuestion') {
			// Start typing the next question while keeping previous Q&A visible
			setDisplayedQuestion("");
			setCharIndex(0);
			setWordIndex(0);
			setCurrentHeadlineIndex((prevIndex) => (prevIndex + 1) % QandA.length);
			setAnimationStage('typing');
		}
	}, [charIndex, wordIndex, currentHeadlineIndex, animationStage]);

	return (
		<section className="animatedDemo">

			<div className="q-and-a-container">
				<div className="question">
					{displayedQuestionIndex >= 0 && <h2>{t(`${QandA[displayedQuestionIndex]}.q`)}</h2>}
				</div>

				<div className="answer">
					<h2>{displayedAnswer}</h2>
				</div>
			</div>

			<div className="search-container">
				<div className="search-input">
					<h2>{animationStage === 'typing' ? displayedQuestion : ""}</h2>
				</div>
				<Link to="/contact">
					<SearchButton />
				</Link>
			</div>
		</section>
	);
}


// const questions = [
// 	"How does Theon help my team work faster?",
// 	"Can Theon handle complex technical documents?",
// 	"Does Theon integrate with our existing systems?",
// 	"What makes Theon better than a cloud-based AI?",
// 	"Can Theon understand industry-specific terminology?",
// 	"How quickly can Theon provide answers?",
// 	"Does Theon require internet access?",
// 	"How do I know Theon’s answers are reliable?",
// 	"Can employees access any document through Theon?",
// 	"Is Theon compliant with data protection laws?",
// 	"How does Theon protect sensitive information?",
// 	"Can Theon be used in regulated industries?",
// 	"Will Theon share our data with third parties?",
// 	"Can Theon help with legal or compliance questions?",
// ];

// const answers = [
// 	"Theon instantly retrieves answers from your company's documents, eliminating time wasted searching through files.",
// 	"Yes! Theon understands and processes even the most detailed reports, manuals, and policies to provide accurate responses.",
// 	"Absolutely. Theon connects with your document management system, databases, and internal tools for seamless access to company knowledge.",
// 	"Theon runs entirely on your local servers, keeping all data private, secure, and fully under your control.",
// 	"Yes! Theon learns from your company's documents, adapting to your industry's language and context.",
// 	"Theon responds in seconds, giving employees instant access to the information they need.",
// 	"No. Theon operates fully offline within your secure company network.",
// 	"Every answer comes with citation(s), so you can verify the source(s) instantly.",
// 	"No. Theon enforces strict Role-Based Access Control (RBAC), ensuring users only see documents they have permission for.",
// 	"Yes. Theon fully complies with the EU AI Act and GDPR, ensuring secure and ethical AI usage.",
// 	"Theon operates entirely on-premises, keeping your data behind your firewall and respecting all access controls.",
// 	"Absolutely. Theon is designed for compliance-heavy environments, ensuring data privacy, auditability, and legal adherence.",
// 	"Never. Unlike cloud-based AI, Theon processes everything locally, ensuring your data stays private.",
// 	"Yes! Theon accurately references company policies, legal documents, and compliance guidelines to provide fact-based answers.",
// ];
