import React from "react";
import { useTranslation } from "react-i18next";
// import useDeviceIsMobile from "../hooks/useDeviceIsMobile";
import { NAME } from "../data/constants";

export default function Footer(): JSX.Element {
	const { t } = useTranslation("Footer");

	// const isMobile = useDeviceIsMobile();
	const currentYear = new Date().getFullYear();
	return (
		<footer>
			{/* <nav>
				<RouterLink to="/legal">{t("footer.legal")}</RouterLink>
				<Link to="contact" smooth={true} duration={500}>
					{t("footer.contact")}
				</Link>
			</nav> */}
			<div className="rights">
				&copy;{currentYear} {NAME}
				{/* {isMobile && <br />} */}
				{" "}
				{t("footer.patent")}
			</div>
		</footer>
	);
}
