import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "emailjs-com";
import { EMAIL } from "../data/constants";
import { useAlert } from "../context/AlertContext";
import Loading from "../components/Loading";
import { isValidEmail } from "../utils/utils";

export default function Testimonials(): JSX.Element {
	const { t } = useTranslation("Contact");

	return (
		<main className="contact">
			<h1 className="sectionHeader">{t("sectionHeader")}</h1>
			<div className="contactContainer">
				<div className="info">
					{/* <img className="bgImg" src={img.BG[e.BG.CONTACT]} /> */}
					<h2 className="sectionHeader">
						{t("subHeader1")}
						<br />
						{t("subHeader2")}
					</h2>
					<p>{t("p")}</p><p>{EMAIL}</p>
				</div>
				<div className="emailSmallScreen">
					<h2>
						{t("subHeader1")} {t("subHeader2")}
					</h2>
					<p>{t("p")}</p> <p>{EMAIL}</p>
				</div>
				<div className="contactForm">
					<ContactForm />
				</div>
			</div>
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
			<br />
		</main>
	);
}

function ContactForm() {
	const { t } = useTranslation("Contact");
	const { triggerAlert } = useAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [formData, setFormData] = useState({ name: "", email: "", company: "", message: "" });

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { name, value } = e.target;
		setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();

		if (formData.name.trim() === "" || formData.email.trim() === "" || formData.company.trim() === "" || formData.message.trim() === "")
			return triggerAlert("Please fill in all fields.");

		if (!isValidEmail(formData.email))
			return triggerAlert("Please enter a valid email address.");

		setIsLoading(true);

		const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID!;
		const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID!;
		const userID = process.env.REACT_APP_EMAILJS_USER_ID!;

		emailjs.send(serviceID, templateID, formData, userID).then(
			(response) => {
				console.log(response.status, response.text);
				triggerAlert(t("success"), "success");
				setFormData({ name: "", email: "", company: "", message: "" });
				setIsLoading(false);
			},
			(error) => {
				console.log("FAILED...", error);
				triggerAlert(t("error"), "error");
				setIsLoading(false);
			}
		);
	};

	if (isLoading) return <Loading />;
	return (
		<form className="contact-form" onSubmit={handleSubmit}>
			<div>
				<label htmlFor="name">{t("form.name")}</label>
				<input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
			</div>
			<div>
				<label htmlFor="email">{t("form.email")}</label>
				<input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
			</div>
			<div>
				<label htmlFor="company">{t("form.company")}</label>
				<input type="text" id="company" name="company" value={formData.company} onChange={handleChange} required />
			</div>
			<div>
				<label htmlFor="message">{t("form.message")}</label>
				<textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
			</div>
			<button className="button-primary" type="submit">{t("form.send")}</button>
		</form>
	);
}
