import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import InfoCard from "../components/InfoCard";
import useImageVisability from "../hooks/useImageVisability";

export default function Security(): JSX.Element {
	const { t } = useTranslation("Security");
	const iconRef = useRef<HTMLDivElement | null>(null);
	const isVisible = useImageVisability(iconRef);

	return (
		<main>
			<section className="features security">
				{/* <img className="bgImg" src={img.BG[e.BG.SECURITY]} /> */}
				<div className="containterHeading">
					<div className="containterText">
						<h2 className="sectionHeader">{t("header")}</h2>
						<h3 className="intro">{t("intro")}</h3>
					</div>
					<img src="img/icons/shield.png" alt="shield" />
				</div>
				<div className="benefitCards" ref={iconRef}>
					<InfoCard isVisible={isVisible} imgPosition={"left"} title={t("1.title")} text={t("1.text")} timeout={100} imgSrc="img/icons/local-area-network.png" />
					<InfoCard isVisible={isVisible} imgPosition={"left"} title={t("2.title")} text={t("2.text")} timeout={500} imgSrc="img/icons/password-code.png" />
					<InfoCard isVisible={isVisible} imgPosition={"left"} title={t("3.title")} text={t("3.text")} timeout={1000} imgSrc="img/icons/confidential.png" />
					<InfoCard isVisible={isVisible} imgPosition={"left"} title={t("4.title")} text={t("4.text")} timeout={1500} imgSrc="img/icons/access-control.png" />
				</div>
			</section>
		</main>
	);
}
