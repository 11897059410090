import React from "react";

export default function Loading(): JSX.Element {
	return (
		<div className="loadingContainer">
			<div className="loadingAnimation">
			</div>
		</div>
	);
}
