import { useEffect, useState } from "react";

export default function useImageVisability(ref: React.RefObject<HTMLDivElement>) {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setIsVisible(true);
					observer.disconnect(); // Stop observing after the first intersection
				}
			});
		});

		const currentRef = ref.current; // Store ref.current in a variable

		if (currentRef) observer.observe(currentRef);

		return () => {
			if (currentRef) observer.unobserve(currentRef); // Use the stored variable
		};
	}, [ref]); // Added ref to the dependency array

	return isVisible;
}
