import React, { useState } from "react";
import { marked } from 'marked';
import { useTranslation } from "react-i18next";
import { Element } from "react-scroll";
import SearchButton from "../components/SearchButton";
import askTheon from "../api/askTheon";
import { storeInFirebase } from "../api/firebase";
import { sanitizeResponseContent } from "../utils/renderResponseLLM";


export default function FAQ(): JSX.Element {
	return (
		<Element name="faq">
			<main className="faq-main">
				<section className="faq">
					<div className="FAQHeader">
						<h2 className="sectionHeader">Want to know more?</h2>
						<h2 className="sectionHeader askTheon">Just ask</h2>
					</div>
				</section>
				<ChatBot />
			</main>
		</Element>
	);
}

function ChatBot() {
	const { t } = useTranslation("FAQ");

	const [searchQuery, setSearchQuery] = useState("");
	const [questions, setQuestions] = useState<string[]>([]);
	const [answers, setAnswers] = useState<string[]>([]);
	const [counter, setCounter] = useState(0);
	const [isGenerating, setIsGenerating] = useState(false);

	const [suggestionCounter, setSuggestionCounter] = useState(0);
	const suggestions = [1, 2, 3, 4, 5];

	function nextSuggestion() {
		setSuggestionCounter((prevCounter) => (prevCounter + 1) % suggestions.length);
	}

	function previousSuggestion() {
		setSuggestionCounter((prevCounter) => (prevCounter - 1 + suggestions.length) % suggestions.length);
	}

	async function handleQuery(question: string) {
		if (question.length === 0 || question.trim() === "") return;
		setSearchQuery("");

		let full_question = question;
		if (questions.length > 0) full_question = `User: ${questions[counter]}\nAssistant: ${answers[counter]}\nUser: ${question}`;

		questions.push(question)
		setQuestions(questions);
		setCounter(questions.length - 1);
		setIsGenerating(true);

		const answer = await askTheon(full_question);
		const answer_html = await marked(answer)
		answers.push(answer_html)
		setAnswers(answers);
		setIsGenerating(false);

		await storeInFirebase("faq", { question: question, answer: answer })
	}

	function previousQuestion() {
		if (counter <= 0) return;
		setCounter(counter - 1);
	}

	function nextQuestion() {
		if (counter >= questions.length - 1) return;
		setCounter(counter + 1);
	}

	return (
		<div className="theon-chat-bot">
			{questions.length > 0 || answers.length > 0 ? (
				<div className="q-and-a-container">
					<div className="question-container">
						<div className="question">
							<h2>{questions[counter]}</h2>
						</div>
						{questions.length > 1 && (
							<div className="question-nav">
								<div className="arrow" onClick={previousQuestion}>&lt;</div>
								{counter + 1} / {questions.length}
								<div className="arrow" onClick={nextQuestion}>&gt;</div>
							</div>
						)}
					</div>
					<div className="answer">
						{isGenerating ? <Skeleton /> : <div dangerouslySetInnerHTML={{ __html: answers[counter] }} />}
					</div>
					<br />
				</div>
			) : (
				<div className="suggestions">
					<h3>Suggestions to get you started</h3>
					<br />
					<div className="suggestion-container">
						<div className="arrow" onClick={previousSuggestion}>&lt;</div>
						<div className="suggestion" onClick={() => handleQuery(t(`${suggestions[suggestionCounter]}.q`))}>
							<h2>{t(`${suggestions[suggestionCounter]}.q`)}</h2>
						</div>
						<div className="arrow" onClick={nextSuggestion}>&gt;</div>
					</div>
				</div>
			)}
			<div className="search-container">
				<input
					className="search-input"
					type="text"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
					onKeyDown={(e) => e.key === 'Enter' && handleQuery(searchQuery)}
				/>
				<div onClick={() => handleQuery(searchQuery)}><SearchButton /></div>
			</div>
		</div >
	);
}

function Skeleton() {
	return (
		<div className="skeleton">
			<div className="animate flex">
				<div className="space">
					<div className="line mr-14" />

					<div className="grid-3">
						<div className="line col-span-2" />
						<div className="line col-span-1" />
					</div>
					<div className="grid-4">
						<div className="line col-span-1" />
						<div className="line col-span-2" />
						<div className="line col-span-1 mr-4" />
					</div>

					<div className="line" />
				</div>
			</div>
		</div>
	);
}
