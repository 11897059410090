export enum Languages {
	DUTCH = "nl",
	ENGLISH = "en",
	GERMAN = "de",
}

export enum BG {
	HOME = "home",
	SECURITY = "security",
	BENEFITS = "features",
	CONTACT = "contact",
}
