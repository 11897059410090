import React, { createContext, useContext, ReactNode, useState, useTransition } from "react";
import * as t from "../data/types";


interface AlertContextProps {
	showAlert: boolean;
	alertConfig: t.AlertConfig;
	triggerAlert: (message: string, type?: "error" | "success" | "") => void;
	closeAlert: () => void;
}
const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export const AlertProvider = ({ children }: { children: ReactNode }) => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertConfig, setAlertConfig] = useState({ message: "", type: "" });
	const [isPending, startTransition] = useTransition();

	const triggerAlert = (message: string, type?: "error" | "success" | "") => {
		startTransition(() => {
			setShowAlert(true);
			setAlertConfig({ message: message, type: type || "" });
		});
	};

	const closeAlert = () => {
		startTransition(() => {
			setShowAlert(false);
			setAlertConfig({ message: "", type: "" });
		});
	};

	return (
		<AlertContext.Provider value={{ showAlert, alertConfig, triggerAlert, closeAlert }}>
			{children}
		</AlertContext.Provider>
	);
};

export const useAlert = () => {
	const context = useContext(AlertContext);
	if (context === undefined) throw new Error("useAlert must be used within a AlertProvider");
	return context;
};
