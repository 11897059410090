import React from "react";
import { useTranslation } from "react-i18next";

export default function Team(): JSX.Element {
	const { t } = useTranslation("Team");

	return (
		<main>
			<section className="features">
				<h2 className="sectionHeader">{t("sectionHeader")}</h2>
				<div className="productCards">
				</div>
			</section>
			<TeamMemberLeft name="Mario Beck" image="img/people/mario.png" role={t("mario.role")}
				linkedin="marioisbeck" p1={t("mario.p1")} p2={t("mario.p2")} />

			<TeamMemberRight name="Milan Weitenberg" image="img/people/milan.png" role={t("milan.role")}
				linkedin="mnweitenberg" p1={t("milan.p1")} p2={t("milan.p2")} />
		</main>
	);
}

function TeamMemberLeft({ name, image, p1, p2, role, linkedin }
	: { name: string, image: string, p1: string, p2: string, role: string, linkedin: string }) {
	return (
		<div className="teamMember">
			<img className="teamPhotoSmall" src={image} alt={name} />
			<img className="teamPhoto" src={image} alt={name} />
			<TeamMember name={name} p1={p1} p2={p2} role={role} linkedin={linkedin} />
		</div>
	);
}

function TeamMemberRight({ name, image, p1, p2, role, linkedin }
	: { name: string, image: string, p1: string, p2: string, role: string, linkedin: string }) {
	return (
		<div className="teamMember">
			<div className="spacer" />
			<img className="teamPhotoSmall" src={image} alt={name} />
			<TeamMember name={name} p1={p1} p2={p2} role={role} linkedin={linkedin} />
			<img className="teamPhoto" src={image} alt={name} />
		</div>
	);
}

function TeamMember({ name, p1, p2, role, linkedin }: { name: string, p1: string, p2: string, role: string, linkedin: string }) {
	return (
		<div className="card">
			<div className="flexRow">
				<a className="nameAndLinkedin" href={`https://www.linkedin.com/in/${linkedin}`} target="_blank" rel="noopener noreferrer">
					<img className="linkedin" src="img/logos/linkedin.png" alt="LinkedIn" />
					<h2>{name}</h2>
				</a>
				<h2>{role}</h2>
			</div>
			<p>{p1}</p>
			<p>{p2}</p>
		</div>
	);
}
