import React, { useEffect, useRef } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import "./styles/style.css";
import Home from "./pages/Home";
import Header from "./components/Header";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";
import Footer from "./components/Footer";
import ErrorBoundary from "./components/ErrorBoundary";
import Features from "./pages/Features";
import { Element } from "react-scroll";
// import Clients from "./pages/Clients";
import Products from "./pages/Products";
import Security from "./pages/Security";
import FAQ from "./pages/FAQ";
import Team from "./pages/Team";
// import TrustedBy from "./pages/TrustedBy";
import Verdic from "./pages/verdic/Verdic";
import { scroller } from "react-scroll";
import { useAlert } from "./context/AlertContext";
import CustomAlert from "./components/Alert";


export default function App() {
	const { showAlert } = useAlert();

	return (
		<ErrorBoundary>
			<Router>
				<div className="App">
					<Header />
					{showAlert && <CustomAlert />}
					<Routes>
						<Route path="/" element={<MainContent />} />
						<Route path="/:section" element={<MainContent />} />
						<Route path="/verdic" element={<Verdic />} />
						<Route path="*" element={<ErrorPage message="Page not found" />} />
					</Routes>
				</div>
			</Router>
		</ErrorBoundary>
	);
}

function MainContent() {
	const location = useLocation();
	const navigate = useNavigate();
	const sectionRef = useRef<HTMLDivElement>(null);

	// Extract section name from URL on initial load or direct navigation
	useEffect(() => {
		// Check if the navigation was triggered by our scroll handler
		if (location.state?.navigatedByScroll) {
			// If yes, do nothing, let the user's scroll position persist
			return;
		}

		const section = location.pathname.substring(1);
		// Only scroll if there's a section name in the URL (and it's not just '/')
		// And if the navigation wasn't caused by the scroll handler itself
		if (section && section !== '/') {
			// Scroll to section after a short delay to ensure DOM is ready
			setTimeout(() => {
				scroller.scrollTo(section, {
					duration: 800,
					delay: 0,
					smooth: true,
				});
			}, 100); // Keep a small delay
		}
	}, [location.pathname, location.state]); // Add location.state to dependency array

	// Handle scroll events to update URL
	useEffect(() => {
		const handleScroll = () => {
			// ... (throttling logic can be added here if needed)
			if (!sectionRef.current) return;

			const sections = ['home', 'features', 'security', 'products', 'faq', 'team', 'contact'];

			// Find which section is currently in view
			for (const section of sections) {
				const element = document.getElementById(section);
				if (!element) continue;

				const rect = element.getBoundingClientRect();
				// Adjust viewport check thresholds if needed
				if (rect.top < window.innerHeight * 0.6 && rect.bottom > window.innerHeight * 0.4) {
					const newPath = section === 'home' ? '/' : `/${section}`;
					if (location.pathname !== newPath) {
						// Navigate with a state flag to indicate this change is due to scrolling
						navigate(newPath, { replace: true, state: { navigatedByScroll: true } });
					}
					break;
				}
			}
		};

		// Debounce or Throttle the scroll handler for better performance
		let scrollTimeout: NodeJS.Timeout;
		const debouncedScrollHandler = () => {
			clearTimeout(scrollTimeout);
			scrollTimeout = setTimeout(handleScroll, 50); // Adjust timeout as needed
		};


		window.addEventListener('scroll', debouncedScrollHandler);
		return () => {
			clearTimeout(scrollTimeout); // Clear timeout on cleanup
			window.removeEventListener('scroll', debouncedScrollHandler);
		}
	}, [location.pathname, navigate]); // location.pathname dependency is important here

	return (
		<div ref={sectionRef}>
			{/* Add id attributes matching the name prop */}
			<Element name="home" id="home"><Home /></Element>
			<Element className="section" name="features" id="features"><Features /></Element>
			<Element className="section" name="security" id="security"><Security /></Element>
			<Element className="section" name="products" id="products"><Products /></Element>
			{/* <Element className="section" name="clients" ><Clients /></Element> */}
			<Element className="section" name="team" id="team"><Team /></Element>
			{/* <Element className="section" name="trustedBy" ><TrustedBy /></Element> */}
			<Element className="section" name="faq" id="faq"><FAQ /></Element>
			<Element className="section" name="contact" id="contact"><Contact /></Element>
			<Footer />
		</div >
	);
}
